(function()
{
 	var app = angular.module("app");
 	var url = window.location.href;
	url = url.substring(url.indexOf("/")+1);
	url = url.substring(url.indexOf("/")+1);
	url = url.substring(url.indexOf("/")+1);

	if(url.indexOf("#") !== -1)
	{
		url = url.substring(0, url.indexOf("#"));
	}

 	app.controller('IndexController', ['$scope', '$rootScope', '$http', '$compile', 'functions', function($scope, $rootScope, $http, $compile, functions)
	{
		$scope.loaded 				= 0;
		$scope.toload 				= 3;
		$scope.view 				= "tiles";
		$scope.topic_q 				= "";
		$rootScope.radius_index 	= $rootScope.distances.length-1
		$rootScope.scale_min 		= 0;
		$rootScope.scale_max 		= 9999;
		$rootScope.transportation 	= 0;
		// $scope.activetab = 0;

		// $scope.topic 	= null;

		app.hideAll = function(callback)
		{
			// jQuery("#location-dialog, #topics-dialog, #options-dialog").removeClass("overlay-show");
			// $scope.$apply();

			// jQuery(".location-entry, .topic-entry, .options-entry").animate(
			// {
			// 	top: 0,
			// 	opacity: 1
			// },500,function()
			// {

			// });

			jQuery("#guide-overlay").fadeOut(500, function()
			{
				jQuery("#location-challenge-dialog").appendTo("#guide-navigation").addClass("hidden");
				jQuery("#location-challenge-picker-dialog").appendTo("#guide-navigation").addClass("hidden");
				jQuery("#location-default-dialog").appendTo("#guide-navigation").addClass("hidden");
				jQuery("#location-dialog").appendTo("#guide-navigation").addClass("hidden");
				jQuery("#topics-dialog").appendTo("#guide-navigation").addClass("hidden");
				jQuery("#options-dialog").appendTo("#guide-navigation").addClass("hidden");

				jQuery(".overlaybg").css({display: 'none'});
				// $scope.activetab = 0;
				if(typeof callback == "function")
				{
					callback();
				}
			});
		};

		app.showLocation = function(event)
		{
			try
			{
				event.preventDefault();
			}
			catch(e){}

			app.hideAll(function()
            {
            	// $scope.activetab = 1;
				// $scope.$apply();
				// jQuery("#location-dialog").addClass("overlay-show");
        jQuery('#guide-navigation').removeClass('hidden');
				jQuery("#location-dialog").appendTo("#guide-overlay").removeClass("hidden");
				// jQuery(".location-entry").animate(
				// {
				// 	top: 50,
				// 	opacity: 0
				// },500,function()
				// {

				// });

				jQuery(".overlaybg").css({display: 'block'});
				jQuery("#guide-overlay").fadeIn(500, function()
				{
					setTimeout(function()
					{
						google.maps.event.trigger(jQuery("#locationpicker").locationpicker('map').map, 'resize');
						jQuery("#locationpicker").locationpicker('map').map.setCenter(new google.maps.LatLng($rootScope.latitude, $rootScope.longitude));
					}, 600);
				});
            });
		};

		app.showLocationChallengePicker = function(event)
		{
			try
			{
				event.preventDefault();
			}
			catch(e){}

			app.hideAll(function()
            {
            	// $scope.activetab = 1;
				// $scope.$apply();
				// jQuery("#location-dialog").addClass("overlay-show");

				jQuery("#location-challenge-picker-dialog").appendTo("#guide-overlay").removeClass("hidden");
				//jQuery("#location-dialog").remove();
				// jQuery(".location-entry").animate(
				// {
				// 	top: 50,
				// 	opacity: 0
				// },500,function()
				// {

				// });
				$scope.locationpicker();
				jQuery(".overlaybg").css({display: 'block'});
				jQuery("#guide-overlay").fadeIn(500, function()
				{
					setTimeout(function()
					{
						google.maps.event.trigger(jQuery("#locationpicker").locationpicker('map').map, 'resize');
						jQuery("#locationpicker").locationpicker('map').map.setCenter(new google.maps.LatLng($rootScope.latitude, $rootScope.longitude));
					}, 600);
				});
            });
		};

		app.showLocationDefault = function(event)
		{
			try
			{
				event.preventDefault();
			}
			catch(e){}

			app.hideAll(function()
            {
            	// $scope.activetab = 1;
				// $scope.$apply();
				// jQuery("#location-dialog").addClass("overlay-show");

				jQuery("#location-default-dialog").appendTo("#guide-overlay").removeClass("hidden");
				// jQuery(".location-entry").animate(
				// {
				// 	top: 50,
				// 	opacity: 0
				// },500,function()
				// {

				// });

				jQuery(".overlaybg").css({display: 'block'});
				jQuery("#guide-overlay").fadeIn(500, function()
				{
				});
            });
		};

		app.closeLocationChallenge = function(event)
		{
			try
			{
				event.preventDefault();
			}
			catch(e){}
			jQuery("#guide-overlay").fadeOut(500, function()
			{
				jQuery("#location-challenge-dialog").appendTo("#guide-navigation").addClass("hidden");
				jQuery(".overlaybg").css({display: 'none'});
			});
		};

		app.closeLocation = function()
		{
			app.hideAll(function()
            {

            });
		};

		app.setLocationReminder = function(event)
		{
			try
			{
				event.preventDefault();
			}
			catch(e){}
			setCookie("location-" + getCookie("location_id"), true , 14);
			jQuery("#guide-overlay").fadeOut(500, function()
			{
				jQuery("#location-challenge-dialog").appendTo("#guide-navigation").addClass("hidden");
				jQuery(".overlaybg").css({display: 'none'});
			});
		};

		app.showTopics = function(event)
		{
			try
			{
				event.preventDefault();
			}
			catch(e){}

			app.hideAll(function()
            {
            	// $scope.activetab = 2;
				// $scope.$apply();
				// jQuery("#topics-dialog").addClass("overlay-show");

				jQuery(".overlaybg").css({display: 'block'});
        jQuery('#guide-navigation').removeClass('hidden');
				jQuery("#topics-dialog").appendTo("#guide-overlay").removeClass("hidden");
				// jQuery(".topic-entry").animate(
				// {
				// 	top: 50,
				// 	opacity: 0
				// },500,function()
				// {

				// });

				jQuery("#guide-overlay").fadeIn(500, function()
				{

				});
            });
		};

		app.showOptions = function(event)
		{
			try
			{
				event.preventDefault();
			}
			catch(e){}

			app.hideAll(function()
            {
            	// $scope.activetab = 3;
				// $scope.$apply();
				// jQuery("#options-dialog").addClass("overlay-show");

				jQuery(".overlaybg").css({display: 'block'});
				jQuery("#options-dialog").appendTo("#guide-overlay").removeClass("hidden");
				// jQuery(".options-entry").animate(
				// {
				// 	top: 50,
				// 	opacity: 0
				// },500,function()
				// {

				// });

				jQuery("#guide-overlay").fadeIn(500, function()
				{

				});
            });
		};

		$scope.doneloading = function()
		{
			$scope.loaded++;

			if($scope.loaded == $scope.toload)
			{
				// functions.loaded();
			}
		};

		$scope.showLocation = function() { app.showLocation(); };
		$scope.showLocationDefault = function() { app.showLocationDefault(); };
		$scope.setLocationReminder = function() { app.setLocationReminder(); };
		$scope.closeLocationChallenge = function() { app.closeLocationChallenge(); };
		$scope.showLocationChallengePicker = function() { app.showLocationChallengePicker(); };
		$scope.showTopics 	= function() { app.showTopics(); };
		$scope.showOptions 	= function() { app.showOptions(); };
		$scope.hideAll 		= function()
		{
			try
			{
				if($rootScope.topic.topic != null)
				{
					app.hideAll(null);
				}
				else
				{
					app.showTopics();
				}
			}
			catch(e)
			{
				app.showTopics();
			}
		};

		$scope.topicSearch = function(t)
		{
			// Trim our query, reduce multiple spaces to a single space, and add regex + after the space.
			var q 	= $scope.topic_q.trim().replace(/\s+/, " ").replace(' ', ' +');
	    	var re 	= new RegExp(".*" + q + ".*", "gi");

	    	if(re.test(t.topic))
	    	{
	    		return true;
	    	}

	    	return false;
	  	};

		// $scope.loadTopic = function(id)
  // 		{
  // 			if(id <= 0)
  // 			{
  // 				return;
  // 			}

  // 			$http.get('/ajaxdata/get/topic/' + id).
		// 		success(function(data, status, headers, config)
		// 		{

		// 			// functions.loaded();
		// 		}).
		// 		error(function(data, status, headers, config)
		// 		{
		// 			error("Error Loading Topic", data, true, null);
		// 		});
  // 		};

  		$scope.getLocation = function()
  		{
  			$scope.spinner = jQuery(".geo .fa").eq(0);
	 		$scope.oldclass = $scope.spinner.attr("class").replace("fa ", "");
	 		$scope.spinner.removeClass($scope.oldclass).addClass("fa fa-cog fa-spin");

  			functions.getlocation(true, function()
			{
				var map 	= jQuery("#locationpicker").locationpicker('map').map;
				var marker 	= jQuery("#locationpicker").locationpicker('map').marker;
				var latlng 	= new google.maps.LatLng($rootScope.latitude, $rootScope.longitude);

				map.setCenter(latlng);
				marker.setPosition(latlng);

				$scope.spinner.removeClass("fa fa-cog fa-spin").addClass("fa-check-circle");

				setTimeout(function()
				{
					$scope.spinner.removeClass("fa-check-circle").addClass($scope.oldclass);

					// try
					// {
					// 	if($rootScope.topic.topic != null)
					// 	{
					// 		app.hideAll(null);
					// 	}
					// 	else
					// 	{
					// 		app.showTopics();
					// 	}
					// }
					// catch(e)
					// {
					// 	app.showTopics();
					// }
				}, 1000);
			});
  		};

  		// $scope.geocode = function()
  		// {
  		// 	functions.geocode(jQuery("#location-q-popup").val());
  		// };

  		$scope.getMatches = function(query, topics)
		{
			if(query == null)
			{
				return topics;
			}

			var results = [];

			for(var i=0; i<topics.length; i++)
			{
				if(topics[i].topic.toLowerCase().indexOf(query.toLowerCase()) >= 0)
				{
					results[results.length] = topics[i];
				}
			}

			return results;
		};

		$scope.goToTopic = function(topic)
		{
			window.location = "/" + topic.location_seo_name + "/" + topic.seo_name;
		};

		$scope.selectTopic = function(e, topic)
		{
			if(e != null)
			{
				e.preventDefault();
			}

			app.hideAll();
			setTimeout(function() { jQuery("#guide-q").val(topic.topic); }, 500);
			setTimeout(function() { $scope.goToTopic(topic); }, 1000);
		};

		var data = 	{ 'location_id': getCookie("location_id")};

		$http(
		{
	    	method: 'POST',
		    url: '/ajaxdata/get_all/topic',
		    data: jQuery.param(data),
		    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
		    timeout: $rootScope.http_worker
		}).
		success(function(data, status, headers, config)
		{
			$scope.topics = data;
			$scope.doneloading();
		}).
		error(function(data, status, headers, config)
		{
		});

		$http(
		{
	    	method: 'POST',
		    url: '/ajaxdata/get_topics_by_subject',
		    data: jQuery.param(data),
		    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
		    timeout: $rootScope.http_worker
		}).
		success(function(data, status, headers, config)
		{
			$scope.topics_by_subject = data;
			$scope.doneloading();
		}).
		error(function(data, status, headers, config)
		{
		});

		$scope.locationpicker = function()
		{
			$('#locationpicker').locationpicker(
			{
				location:
				{
					latitude: $rootScope.latitude,
					longitude: $rootScope.longitude
				},
				radius: 0,
				enableAutocomplete: true,
				enableReverseGeocode: true,
				scrollwheel: false,
				zoom: 8,
				inputBinding:
				{
			        locationNameInput: $('#location-q-popup')
			    },
			    onchanged: function(currentLocation, radius, isMarkerDropped)
			    {
			    	var location_details = $(this).locationpicker('map').location;

					$rootScope.location 		= location_details.formattedAddress;
			    	$rootScope.new_location 	= $rootScope.location;
			    	$rootScope.location_display = location_details.addressComponents.city + ", " + location_details.addressComponents.stateOrProvince + ", " + location_details.addressComponents.postalCode;
					$rootScope.address 			= location_details.addressComponents.addressLine1;
					$rootScope.postalcode 		= location_details.addressComponents.postalCode;
			    	$rootScope.latitude 		= currentLocation.latitude;
			    	$rootScope.longitude 		= currentLocation.longitude;
			    	$rootScope.city 			= location_details.addressComponents.city;
					$rootScope.province 		= location_details.addressComponents.stateOrProvince;
					$rootScope.province_code 	= location_details.addressComponents.stateOrProvince;
					$rootScope.unit 			= (location_details.addressComponents.country == "CA" ? "metric" : "imperial");

			    	// functions.geocode(currentLocation.latitude, currentLocation.longitude);
				}
			});
		}

		$scope.locationchallenge = function()
		{
			jQuery("#location-challenge-dialog").appendTo("#guide-overlay").removeClass("hidden");
      jQuery('#guide-navigation').removeClass('hidden');
			jQuery(".overlaybg").css({display: 'block'});
			jQuery("#guide-overlay").fadeIn(500, function()
			{

			});
		}

		let gotolist = function(e)
		{
			e.preventDefault();

			jQuery("#overlay").animate(
			{
				opacity: "toggle"
			}, 300, function()
			{
				jQuery("#status-dialog").appendTo("body").addClass("hidden");
			});

			// window.location = "#locations";
		}

		jQuery(document).ready(function()
		{
			if(getCookie("location_id") != "" && getCookie("location-"+getCookie("location_id")) == ""){
				$scope.locationchallenge();
				setCookie("location-" + getCookie("location_id"), true, 365*10);
			}else{
				$scope.locationpicker();
			}

			// if(url == "")
			// {
			// 	$rootScope.check_location();
			// }
			// else
			// {
			// 	app.showTopics();
			// }

			// $('#location-q-popup').change(function(e)
			// {
			// 	functions.geocode(jQuery(this).val());
			// });

			// if(navigator.geolocation == false)
			// {
			// }
		});

        $rootScope.$watch
		(
			function(scope)
			{
				return $rootScope.latitude;
			},
          	function(newValue, oldValue)
          	{
          		if(newValue == oldValue) { return; }
          		$scope.locationpicker();
    //       		if(url == "")
				// {
				// 	$rootScope.check_location();
				// }
          	}
        );

        $rootScope.$watch
		(
			function(scope)
			{
				return $rootScope.longitude;
			},
          	function(newValue, oldValue)
          	{
          		if(newValue == oldValue) { return; }
				$scope.locationpicker();
				// if(url == "")
				// {
				// 	$rootScope.check_location();
				// }
          	}
        );

        $rootScope.$watch
		(
			function(scope){ return $rootScope.location_display; },
          	function(newValue, oldValue)
          	{
          		if(newValue == oldValue) { return; }
          	}
        );

		$scope.$on("$destroy", function()
		{

	    });
	}]);
})();
